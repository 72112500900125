import React from "react";
import { motion } from "framer-motion";

const ProjectCards = ({
    class_spec,
    category,
    title,
    desc,
    videoURL,
    order,
}) => {
    return (
        <motion.div className={`project relative py-32 ${class_spec}`}>
            <motion.div
                initial={{ opacity: 0, translateX: -30 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{ duration: 0.6 }}
                className={`contain flex lg:gap-24 gap-16 flex-col lg:flex-row relative -order-[${order}]`}
            >
                <div className="video-info flex flex-col lg:w-[45%]">
                    <h4 className="text-xl lg:text-2xl mb-2 uppercase">
                        {category}
                    </h4>
                    <h2 className="text-6xl lg:text-7xl font-bold uppercase mb-8 lg:mb-16">
                        {title}
                    </h2>
                    <p className="text-base font-light">{desc}</p>
                </div>
                <div className="video-project">
                    <video className="w-full h-full video" loop muted autoPlay>
                        <source src={videoURL} type="video/mp4" />
                    </video>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default ProjectCards;
