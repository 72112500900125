import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ProjectCards from "../../utils/ProjectCards";

const Projects = () => {
    const [allVideos, setAllVideos] = useState([]);
    const getProjectItems = async () => {
        const res = await fetch(
            "https://mucahit-website-api.onrender.com/getdata"
        );
        const data = await res.json();

        setAllVideos(data);
        console.log(data);
    };

    useEffect(() => {
        getProjectItems();
    }, []);

    console.log(allVideos);

    return (
        <motion.section
            className="projects"
            initial="offscreen"
            whileInView="onscreen"
        >
            {allVideos.map((card, index) => {
                let cls = "right";
                if (index % 2 === 0) {
                    cls = "left";
                }
                return (
                    <ProjectCards
                        key={index}
                        class_spec={cls}
                        title={card.title}
                        order={card.order}
                        category={card.category}
                        desc={card.desc}
                        filename={card.filename}
                        videoURL={card.videoURL}
                    />
                );
            })}
        </motion.section>
    );
};

export default Projects;
