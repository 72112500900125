import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { LiaBarsSolid } from "react-icons/lia";
import { AnimatePresence, motion } from "framer-motion";

const Header = () => {
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <motion.header
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-black relative text-[#FAFAFA] justify-between flex items-center py-8 px-16"
            id="header"
        >
            <div className="logo flex-[1] justify-start flex">
                <Link
                    to={"/"}
                    className="font-bold outline-none text-base py-1.5 px-2 border-2 border-white"
                >
                    <span className="mletter">M</span>
                    <span className="kletter">K</span>
                </Link>
            </div>
            {/* desktop menu */}
            <div className="navigation flex-[2] justify-center hidden lg:flex">
                <ul className="flex gap-8 font-bold text-base">
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to={"/work"} className="">
                            WORK
                        </Link>
                    </li>
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to={"/about"} className="">
                            ABOUT
                        </Link>
                    </li>
                    <li onClick={() => setMobileMenu(false)}>
                        <Link to={"/contact"} className="">
                            CONTACT
                        </Link>
                    </li>
                </ul>
            </div>

            {/* mobile menu */}

            <AnimatePresence>
                {mobileMenu && (
                    <motion.div
                        initial={{ opacity: 0, translateY: 50 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        exit={{ opacity: 0, translateY: 20 }}
                        className="text-5xl absolute top-0 bg-black z-50 left-0 h-[100vh] w-full lg:hidden select-none"
                    >
                        <div className="times relative w-full">
                            <LiaTimesSolid
                                fontSize={48}
                                className="absolute right-8 top-8"
                                onClick={() => setMobileMenu(false)}
                            />
                        </div>
                        <ul className="flex justify-center items-center flex-col gap-12 font-semibold h-full">
                            <li onClick={() => setMobileMenu(false)}>
                                <Link to={"/work"} className="">
                                    WORK
                                </Link>
                            </li>
                            <li onClick={() => setMobileMenu(false)}>
                                <Link to={"/about"} className="">
                                    ABOUT
                                </Link>
                            </li>
                            <li onClick={() => setMobileMenu(false)}>
                                <Link to={"/contact"} className="">
                                    CONTACT
                                </Link>
                            </li>
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="social gap-5 flex-[1] justify-end hidden lg:flex">
                <Link to={"https://www.instagram.com/kchnmuco/?hl=tr"}>
                    <FaInstagram fontSize={20} />
                </Link>
                <Link>
                    <FaTwitter fontSize={20} />
                </Link>
                <Link to={"https://www.linkedin.com/in/m%C3%BCcahit-ko%C3%A7han-606660308/"}>
                    <FaLinkedinIn fontSize={20} />
                </Link>
            </div>
            <button className="lg:hidden" onClick={() => setMobileMenu(true)}>
                <LiaBarsSolid fontSize={36} />
            </button>
        </motion.header>
    );
};

export default Header;
