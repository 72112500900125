import React from "react";
import { motion } from "framer-motion";

const Main = () => {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="relative video-main select-none"
        >
            <div className="absolute w-full h-full main flex items-center text-center justify-center flex-col text-[#fafafa] bg-black">
                <div className="relative z-20">
                    <h1 className="mb-10 text-[14vw] md:text-[8vw] lg:text-[5vw] leading-none tracking-widest font-bold">
                        MUCAHIT KOCHAN
                    </h1>
                    {/* <hr className="line w-40 bg-white h-0.5 m-auto mb-8"></hr> */}

                    <div className="flex justify-center items-center mb-10">
                        <div className="loader"></div>
                    </div>

                    <h2 className="text-2xl font-bold uppercase">
                        Videographer
                    </h2>
                </div>
            </div>

            <div className="!w-[100%] h-full relative z-10">
                <video className="w-full h-full video" loop muted autoPlay>
                    <source src="/videos/waves.mp4" type="video/mp4" />
                </video>
            </div>
        </motion.main>
    );
};

export default Main;
