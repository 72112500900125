import React from "react";

const Blockquote = () => {
    return (
        <div className="bg-black flex justify-center items-center">
            <p className="text-3xl lg:text-4xl contain text-white font-bold text-center py-28 !w-[90vw] md:!w-[60vw] lg:!w-[40vw]">
                I’M ALWAYS SEARCHING FOR THE SMALL MOMENTS AND BIG TRUTHS. I
                WANT TO TELL STORIES THAT INSPIRE US TO BE{" "}
                <span className=" italic">BETTER</span>
            </p>
            
        </div>
    );
};

export default Blockquote;
