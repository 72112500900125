import React from "react";
import Main from "../../components/Main/Main";
import Blockquote from "../../components/Blockquote/Blockquote";
import Projects from "../../components/Projects/Projects";

const HomePage = () => {
    return (
        <main>
            <Main />
            <Blockquote />
            <Projects />
        </main>
    );
};

export default HomePage;
