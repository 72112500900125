import React from "react";
import { motion } from "framer-motion";

const About = () => {
    return (
        <section className="about py-16 pb-24 bg-black">
            <div className="contain">
                <div className="content flex flex-col lg:flex-row lg:gap-20 gap-12 mb-24">
                    <div className="author-image lg:w-[40%]">
                        <motion.img
                            initial={{ opacity: 0, translateX: -30 }}
                            animate={{ opacity: 1, translateX: 0 }}
                            src="/images/mucahit-photo.png"
                            className="w-full object-cover"
                            alt=""
                        />
                    </div>
                    <motion.div
                        initial={{ opacity: 0, translateY: -30 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        className="about-paragraphs py-4 lg:w-[60%]"
                    >
                        <div>
                            <h2 className="text-6xl text-[#fafafa] font-bold mb-2">
                                MUCAHIT KOCHAN
                            </h2>
                            <span className="mb-8 block text-white text-xl font-medium italic">
                                VIDEOGRAPHER
                            </span>
                        </div>
                        <div className="text-[#fafafa] text-[15px] font-light">
                            <p className="mb-4">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Amet perferendis accusantium
                                veniam! Tempora reiciendis numquam porro eaque
                                rerum! Delectus vitae reprehenderit accusamus
                                ratione aspernatur! Fugit ipsum, nulla error
                                voluptates ipsam, itaque reiciendis, est sed
                                assumenda illo doloremque harum? Pariatur
                                excepturi nam commodi libero magni repellendus
                                eius sequi dolor, asperiores ipsam aperiam at
                                accusamus ex placeat labore tenetur vitae rem
                                iste itaque, totam incidunt alias reprehenderit.
                                Aliquid inventore fugiat, molestias doloribus
                                illum voluptate tenetur et adipisci quas ipsa
                                quasi voluptatem esse facilis quam. Ipsum dolore
                                animi repellat nam beatae perspiciatis,
                                asperiores quas tempora porro ab vero, error
                                quod qui dolorem? Harum voluptatem quod
                                doloremque quas, beatae ad laudantium odit nam
                                est. Optio, dolor ipsum fuga, recusandae officia
                                laboriosam veritatis eos perferendis nihil aut
                                dolorem deleniti velit sapiente cupiditate,
                                alias consequatur nam ad. Saepe repellat fuga
                                dicta, corrupti porro cumque eligendi. Maxime
                                nulla laborum alias quo ipsam amet doloribus
                                dignissimos nobis? Aperiam sit nam quasi,
                                adipisci, earum perferendis sed eligendi dolorum
                                beatae cum molestias, nesciunt quia natus alias!
                                Dolor odit qui neque quas, omnis rerum tempore
                                harum libero doloribus placeat. Atque,
                                voluptatibus quas! Eligendi distinctio rem
                                similique quod, quasi eum necessitatibus
                                laboriosam neque tenetur temporibus minus
                                perspiciatis veniam quas. Ab, adipisci amet.
                            </p>

                            <p className="mb-4">
                                Lorem ipsum, dolor sit amet consectetur
                                adipisicing elit. Aperiam alias omnis
                                laboriosam, dolorem dicta dolores eos fuga.
                                Sapiente eaque ea voluptate, id ut nam
                                recusandae provident quasi voluptatum illo
                                dignissimos quis labore commodi veritatis
                                repellat porro ipsum? Molestias recusandae aut
                                deserunt temporibus nam, magnam eum illo porro
                                enim incidunt obcaecati sit repellendus quam
                                adipisci rem dolorem odit accusamus fuga
                                corrupti vero magni quo doloremque dolorum! Cum
                                a esse, reiciendis laborum expedita nesciunt!
                                Libero et sequi sed ullam molestias iure
                                architecto illum, asperiores ratione cumque ex
                                vero consectetur nostrum, ad velit eligendi sunt
                                dolor nihil illo tempore fugiat. Quis, aut
                                laudantium!
                            </p>
                        </div>
                    </motion.div>
                </div>
                <motion.div
                    initial={{ opacity: 0, translateY: 30 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    className="blockquote text-center"
                >
                    <p className="text-[#fafafa] text-xl font-semibold break-words">
                        "A good photograph is knowing where to stand"{" "}
                        <span className="text-[#474747]">- Ansel Adams</span>
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default About;
