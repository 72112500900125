import React, { useEffect, useState } from "react";
import "./Admin.css";
import { useNavigate } from "react-router-dom";

const Admin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = "https://mucahit-website-api.onrender.com";
    }, []);

    return null;
};

export default Admin;
