import React from "react";
import { motion } from "framer-motion";
import workImages from "../../data/WorkImages.json";

const Work = () => {
    const container = {
        visible: {
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const item = {
        hidden: {
            opacity: 0,
            translateY: 20,
        },
        visible: {
            opacity: 1,
            translateY: 0,
        },
    };
    return (
        <motion.section className="bg-black py-16">
            <div className="contain">
                <motion.div
                    className="photographs"
                    variants={container}
                    animate="visible"
                    initial="hidden"
                    // initial="hidden"
                    // animate="visible"
                    // transition={{
                    //     staggerChildren: 1,
                    // }}
                >
                    {workImages.images.map((image) => (
                        <motion.div
                            variants={item}
                            // initial={{ opacity: 0, translateY: 30 }}
                            // whileInView={{
                            //     opacity: 1,
                            //     translateY: 0,
                            // }}
                            // transition={{
                            //     staggerChildren: 1,
                            // }}
                            className="photo overflow-hidden"
                            key={image.image_id}
                        >
                            <img className=" hover:!scale-[1.03] transition" src={image.image_url} alt="" />
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </motion.section>
    );
};

export default Work;
