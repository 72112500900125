import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
    return (
        <section className="contact bg-black">
            <div className="contain">
                <motion.div
                    initial={{ opacity: 0, translateY: -30 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    className="h-[calc(100vh_-_104px)] flex items-center justify-center"
                >
                    <div className="contact-background z-20 relative lg:w-[60%] p-12 py-24 border-2 border-white lg:-mt-32 mb-16 flex flex-col items-center gap-12">
                        <h2 className="font-semibold text-3xl z-20 lg:text-5xl text-[#fafafa] text-center leading-snug ">
                            LET’S CREATE SOMETHING BEAUTIFUL, TOGETHER.
                        </h2>
                        <button className="uppercase z-20 bg-[#fafafa] border-2 border-[#fafafa] hover:bg-transparent hover:text-[#fafafa] transition text-black font-bold py-5 px-6">
                            Make Contact
                        </button>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Contact;
