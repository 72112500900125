import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer py-16 pb-8">
            <div className="contain flex flex-col lg:flex-row items-center gap-16 lg:gap-0 lg:justify-between">
                <div className="name font-bold text-lg text-white flex-[1] justify-start">
                    <span className="year text-[#474747]">2024</span>
                    <span>MUCAHITKOCHAN</span>
                </div>
                <div className="logo flex-[2] flex justify-center ">
                    <a
                        href="#header"
                        className="font-bold text-2xl py-2.5 px-2 border-2 border-white text-white hover:scale-105 transition"
                    >
                        MK
                    </a>
                </div>
                <div className="text-white flex gap-10 flex-[1] justify-end">
                    <Link to={"https://www.instagram.com/kchnmuco/?hl=tr"}>
                        <FaInstagram fontSize={20} />
                    </Link>
                    <Link>
                        <FaTwitter fontSize={20} />
                    </Link>
                    <Link to={"https://www.linkedin.com/in/m%C3%BCcahit-ko%C3%A7han-606660308/"}>
                        <FaLinkedinIn fontSize={20} />
                    </Link>
                </div>
            </div>
            <div className="policy text-sm text-center text-[#aaa] mt-12">
                Created by Kubbely © All Rights Reserved
            </div>
        </footer>
    );
};

export default Footer;
