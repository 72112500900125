import React from "react";
import { motion } from "framer-motion";

const Login = () => {
    return (
        <div className="login h-[calc(100vh_-_104px)] bg-black select-none flex pt-16 justify-center">
            <div className="login-contain">
                <motion.div
                    initial={{ opacity: 0, translateY: -30 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    className="flex flex-col lg:flex-row justify-center w-full items-center relative"
                >
                    <div className="lg:w-[50%] h-full hidden lg:block relative">
                        <img
                            src="/images/login.jpg"
                            className="w-full h-[504px] object-cover relative"
                            alt=""
                        />
                    </div>
                    <form className="login-form lg:p-16 p-8 bg-white flex flex-col gap-8 items-center w-full lg:w-[50%]">
                        <h2 className="text-4xl font-semibold ">Log In</h2>
                        <div className="flex flex-col gap-1 w-full">
                            <span>Email</span>
                            <input
                                type="text"
                                className="p-4 border-2 border-black outline-none w-full"
                                placeholder="Email"
                            />
                        </div>
                        <div className="flex flex-col gap-1 w-full">
                            <span>Password</span>
                            <input
                                type="text"
                                className="p-4 border-2 border-black outline-none"
                                placeholder="Password"
                            />
                        </div>
                        <button className="py-4 px-7 bg-black border-2 hover:text-black border-black hover:bg-white transition text-[#fafafa] font-semibold text-lg">
                            Log In
                        </button>
                    </form>
                </motion.div>
            </div>
        </div>
    );
};

export default Login;
